import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Tabs from 'components/common/tabs'
import ContentWrapper from "styles/contentWrapper"


const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.white};
  padding: 3rem 1rem;
  .section-title {
    margin-bottom: 3rem;
    padding: 0 1rem;
  }
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 0;
    padding-left: 0;
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      padding-right: 2.5rem;
      padding-left: 2.5rem;
    }
  }
`

const StyledInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .tab-list {
    justify-content: center;
    margin-top: 0;
    margin-bottom: 3rem;
    &-item {
      font-size: 1.1rem;
      color: ${({ theme }) => theme.colors.secondary};
      &:hover {
        color: ${({ theme }) => theme.colors.primary};
      }
      &:before {
        background-color: ${({ theme }) => theme.colors.primary};
      }
    }
    &-active {
      color: ${({ theme }) => theme.colors.primary}!important;
    }
  }
  .items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: minmax(12.5rem, auto);
      grid-gap: 1rem;
    }
    .item-wrapper {
      width: 24.5rem;
      height: 12.5rem;
      margin: .75rem;
      border: .1rem solid #bddae3;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border-radius: 1.5rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
        max-width: 250px;
      }
    }
  }
}

`

const Companies = ({ content }) => {
  const { exports, frontmatter } = content[0].node
  const { items } = exports
  return (
    <StyledSection id="companies">
      <StyledContentWrapper>
        <h2 className="section-title">{frontmatter.title}</h2>
        <StyledInner>
          <Tabs  minHeight="unset" maxHeight="unset"> 
            <div label="Corporates">
              <div className="items">
                {items.filter(item => item.category === 'Corporate').map(({ image }, key) => {
                  const logo = getImage(image)
                  return (
                    <div className="item-wrapper" key={key}>
                      <GatsbyImage
                        className="logo"
                        image={logo}
                        alt={`Corporate logo ${key}`}
                      />
                    </div>
                  )
                })}
              </div>
            </div> 
            <div label="Consulting">
              <div className="items">
                {items.filter(item => item.category === 'Consulting').map(({ image }, key) => {
                  const logo = getImage(image)
                  return (
                    <div className="item-wrapper" key={key}>
                      <GatsbyImage
                        className="logo"
                        image={logo}
                        alt={`Consulting logo ${key}`}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
            <div label="Staffing"> 
              <div className="items">
                {items.filter(item => item.category === 'Staffing').map(({ image }, key) => {
                  const logo = getImage(image)
                  return (
                    <div className="item-wrapper" key={key}>
                      <GatsbyImage
                        className="logo"
                        image={logo}
                        alt={`Staffing logo ${key}`}
                      />
                    </div>
                  )
                })}
              </div>
            </div> 
            <div label="HR Software"> 
              <div className="items">
                {items.filter(item => item.category === 'HR Software').map(({ image }, key) => {
                  const logo = getImage(image)
                  return (
                    <div className="item-wrapper" key={key}>
                      <GatsbyImage
                        className="logo"
                        image={logo}
                        alt={`HR Software logo ${key}`}
                      />
                    </div>
                  )
                })}
              </div>
            </div>  
            <div label="VC"> 
            <div className="items">
                {items.filter(item => item.category === 'VC').map(({ image }, key) => {
                  const logo = getImage(image)
                  return (
                    <div className="item-wrapper" key={key}>
                      <GatsbyImage
                        className="logo"
                        image={logo}
                        alt={`VC logo ${key}`}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
            <div label="Startups">
              <div className="items">
                {items.filter(item => item.category === 'Startup').map(({ image }, key) => {
                  const logo = getImage(image)
                  return (
                    <div className="item-wrapper" key={key}>
                      <GatsbyImage
                        className="logo"
                        image={logo}
                        alt={`Startup logo ${key}`}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          </Tabs> 
        </StyledInner>
      </StyledContentWrapper>
    </StyledSection>
  )
}


export default Companies